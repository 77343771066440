<template>
  <el-form>
    <el-input
      @input.native="debouncedSearch($event.target.value)"
      :placeholder="placeholder"
      class="search"
      v-model="value"
    >
      <i class="icon ion-ios-search el-input__icon" slot="prefix"> </i>
    </el-input>
  </el-form>
</template>
<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: false,
      default: () => 'Search...',
    },
  },
  data() {
    return {
      value: '',
      timer: undefined,
    }
  },
  watch: {
    '$route.path'() {
      this.value = ''
    },
  },
  methods: {
    debounce(callback, delay) {
      clearTimeout(this.timer)
      this.timer = setTimeout(callback, delay)
    },
    debouncedSearch(value) {
      this.debounce(() => this.$emit('input', value), 500)
    },
  },
}
</script>
